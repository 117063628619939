body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.button {
    outline: none;
    box-shadow: none;
}

.on-hover-zoom:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}

.on-hover-color-primary:hover {
    background-color: #0d6dfda2;
    box-shadow: 0px 0px 4px 1px #6c757d;
    padding: 2px;
    color: white !important;
    transition: all 0.2s ease-in-out;
}

.on-hover-color-danger:hover {
    background-color: #dc3546c5;
    box-shadow: 0px 0px 4px 1px #6c757d;
    padding: 2px;
    color: white !important;
    transition: all 0.2s ease-in-out;
}